<template>
	<b-modal v-if="form" size="xl" ref="modalAddResa">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.reservation.ajouter_reservation') }}</h2>
		</template>
		<template slot="default">
			<div class="form-group">
				<label>{{ $t('monte.reservation.centre_stockage') }}</label>
				<e-select
					v-model="form.expediteur"
					id="expediteur"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('monte.reservation.choisir_centre_stockage')"
					:selectedLabel="$t('global.selected_label')"
					:options="available_centre_stockage"
					:allow-empty="true"
					:show-labels="false"
				></e-select>
			</div>

			<div class="form-group">
				<Etalon 
					v-model="form.stallion"
					:stallion_id="actualStallion"
					:season="actualSeason"
					:update_config="false"
				/>
			</div>

			<div class="form-group" v-if="contract_id == 0">
				<label>{{ $t('monte.contrat') }}</label>
				<e-select
					v-model="contract"
					id="contract"
					track-by="contract_id"
					label="contract_num"
					:placeholder="$t('monte.reservation.choisir_contrat')"
					:selectedLabel="$t('global.selected_label')"
					:options="contracts"
					:allow-empty="true"
					:show-labels="false"
					:loading="loading_contract"
					@input="hasSelectedContract"
				></e-select>
			</div>

			<div class="form-group">
				<label>{{ $t('monte.reservation.destinataire') }} *</label>
				<e-select
					v-model="form.destinataire"
					id="destinataire"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('monte.reservation.choisir_destinataire')"
					:selectedLabel="$t('global.selected_label')"
					:options="available_tiers"
					:allow-empty="true"
					:show-labels="false"
				></e-select>
			</div>

			<div class="form-group">
				<label>{{ $t('monte.reservation.client') }}</label>
				<e-select
					v-model="form.client"
					id="client"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('monte.reservation.choisir_client')"
					:selectedLabel="$t('global.selected_label')"
					:options="available_tiers"
					:allow-empty="true"
					:show-labels="false"
				></e-select>
			</div>
			
			<div class="form-group">
				<label>{{ $t('monte.jument') }}</label>
				<e-select
					v-model="form.mare"
					id="mare"
					track-by="horse_id"
					label="horse_nom"
					:placeholder="$t('monte.reservation.choisir_jument')"
					:selectedLabel="$t('global.selected_label')"
					:options="available_mares"
					:allow-empty="true"
					:show-labels="false"
				></e-select>
			</div>

			<div class="form-group">
				<label>{{ $t('monte.reservation.quantity') }} *</label>
				<div class="row">
					<div class="col">
                        <div class="form-group">
							<b-form-input
								class="mb-4"
								v-model="quantity"
								type="number"
							>
							</b-form-input>
						</div>
					</div>
					<div class="col">
                        <div class="form-group">
							<e-select
								class="mb-4"
								v-model="quantity_type"
								:placeholder="$t('monte.reservation.choisir_type')"
								:selectedLabel="$t('global.selected_label')"
								:options="quantity_types"
								:allow-empty="true"
								:show-labels="false"
							>
                            	<template slot="singleLabel" slot-scope="{ option }">{{ $t(`monte.reservation.order_type.${option}`) }}</template>
								<template slot="option" slot-scope="{ option }">{{ $t(`monte.reservation.order_type.${option}`) }}</template>
							</e-select>
						</div>
					</div>
				</div>
			</div>
			
			<div class="form-group">
				<label>{{ $t('monte.reservation.echeance') }}</label>
				<e-datepicker
					v-model="form.delivery_date"
				/>
			</div>

			<div class="form-group">
				<label>{{ $t('monte.reservation.commentaire') }}</label>
				<b-form-textarea
					id="form_comment"
					v-model="form.comment"
					:placeholder="$t('monte.reservation.saisir_commentaire')"
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</div>
			

			<div class="col-12" v-if="form_error">
				<ErrorAlert :messageI18n="form_error" />
			</div>
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="checkForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.sauvegarder') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
	import Common from '@/assets/js/common'
	import _cloneDeep from 'lodash/cloneDeep'
	import _last from 'lodash/last'
	import _sortBy from 'lodash/sortBy'
	import Contract from "@/mixins/Contract.js"
	import Gynecologie from "@/mixins/Gynecologie.js"
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"

	export default {
		name: 'ModalAdd',
		mixins: [Contract, Gynecologie, MonteReservationOrder],
		props: {
			contract_id: { type: Number, default: 0 },
			available_tiers: { type: Array, default: () => [] },
			available_centre_stockage: { type: Array, default: () => [] },
			available_mares: { type: Array, default: () => [] },
			season: { type: Object, default: null },
			stallion: { type: Object, default: null },
			mare: { type: Object, default: null },
			cmep: { type: Object, default: null }
		},
		data () {
			return {
				processing: false,
				loading_contract: false,
				form_error: '',
				monte_resa_id: 0,
				quantity: 0,
				quantity_type: 'paillettes',
				quantity_types: ['doses', 'paillettes'],
				contracts: [],
				contract: null,
				avenant: null,
				seasons: [],

				default_form: {
					destinataire: null,
					expediteur: null,
					client: 	null,
					contract: 	0,
					stallion: 	null,
					mare: 		null,
					order: 		null,
					doses: 		0,
					paillettes: 0,
					comment: 	'',
					delivery_date: null,
				},
				form: null,
				canceled: false // si la ligne doit être annulée et recrée (sinon juste modifiée)
			}
		},
		computed: {
			actualSeason() {
				if(this.season) return this.season
				return this.seasons.find(s => s.season_default === true)
			},
			actualStallion() {
				return this.stallion ? this.stallion.horse_id :
					(this.form.stallion ? this.form.stallion.horse_id : 0 )
			},
 			canChooseContract() {
				return this.contract_id == 0 && Boolean(this.form) && Boolean(this.form.stallion)
			}
		},
		watch: {
			canChooseContract(val) {
				if(val === true) this.loadStallionContracts()
				else this.contracts = []
			},
			mare(val) {
				if(val) {
					this.form.mare = val
				}
			},
			cmep(val) {
				if(val) {
					this.form.destinataire = val
				}
			}
		},
		created() {
			this.preinit_component()
		},
		mounted() {
			this.init_component()
		},
		methods: {
			preinit_component() {
				this.resetModal()
			},
			async init_component() {
				// await this.$sync.force(true, true)
				// Si on est dans l'édition d'un contrat, on charge les infos pour auto complete le form
				if(this.contract_id) {
					const infos_contract = await this.getContractOnline(this.contract_id)
					this.contract = infos_contract[0].contract
					this.avenant = infos_contract[0].avenant
					this.hasSelectedContract()
				}

				// Si pas de contrat, on va chercher la saison actuelle pour proposer des contrats
				if(this.contract_id == 0 || !this.season) {
					this.seasons = await this.loadSeasons()
				}
			},
			async loadStallionContracts() {
				this.loading_contract = true
				this.contracts = await this.getAvenantOfStallion(this.form.stallion.horse_id)
				this.loading_contract = false
			},
			async hasSelectedContract() {
				if(this.mare) {
					this.form.mare = this.mare
				}
				else {
					this.form.mare = this.getActualMare()
				}
				
				if(this.cmep) {
					this.form.destinataire = this.cmep
				}
				else {
					this.form.destinataire = this.getActualDestinataire()
				}

				// Si on ajoute une résa à partir de la liste, on a pas de détails sur le contrats
				if(!this.contract.contract_tiers) {
					const infos_contract = await this.getContractOnline(this.contract_id)
					const temp = infos_contract[0].contract
					this.form.client = temp.tiers
				}
				// Si on ajoute à partir d'une édition de contrat, on a déjà les détails du contrat séléctionné
				else {
					this.form.client = this.contract.tiers
				}
			},
			getActualDestinataire() {
				if(this.contract) {
					const avenant = this.extractLastContractAvenant(this.contract)
					let tiers_id = 0

					if(avenant.cmep) {
						tiers_id = avenant.cmep.tiers_id
					}
					else if(avenant.avenant_cmep) {
						tiers_id = avenant.avenant_cmep
					}
					return this.available_tiers.find(t => t.tiers_id == tiers_id)
				}
				return null
			},
			getActualMare() {
				if(this.contract) {
					const avenant = this.extractLastContractAvenant(this.contract)
					return this.available_mares.find(h => h.horse_id == avenant.avenant_horse)
				}
				return null
			},
			extractLastContractAvenant(contract) {
				if(!Array.isArray(this.avenant)) return this.avenant

				const avenants  = contract.avenant.map(a => {
					return { ...a, avenant_num_clean: parseInt(a.avenant_num) }
				})
				return _last(_sortBy(avenants, 'avenant_num_clean'))
			},
			openModal(resa_id=0, canceled = false) {
				this.monte_resa_id = resa_id
				this.canceled = canceled

				// Modif d'une résa
				if(resa_id > 0) {
					// On récupére le contrat de la resa pour remplir le form
					this.getContractSailliesReservation(resa_id)
						.then(res => {
							let tmp = res
							delete tmp.id
							delete tmp.status

							tmp.delivery_date = res.delivery_date ? new Date(tmp.delivery_date) : null
							this.form = tmp
							this.quantity = tmp.doses || tmp.paillettes

							// Si on a un contrat déjà spécifié dans la résa, on cherche à l'auto select
							if(res.contract) {
								// Si la liste des contrats de l'étalon séléctionné ne sont pas encore en cours de chargement
								if(!this.loading_contract) {
									this.loadStallionContracts()
										.then(() => {
											this.contract = this.contracts.find(c => c.contract_id == res.contract.contract_id)
										})
								}
								// Si les contrats chargent (suite à la mutation de canChooseContract), on attend que l'opération soit terminée
								else {
									Common.waitUntil(() => (this.loading_contract === false), 30000)
										.then(() => {
											this.contract = this.contracts.find(c => c.contract_id == res.contract.contract_id)
										})
								}
							}

							if(tmp.doses > 0) this.quantity_type = 'doses'
							else this.quantity_type = 'paillettes'
						})
				}
				else if(this.contract_id) {
					// 
				}
				else {
					// Pour un ajout classique
					this.resetModal()
				}

				this.$refs.modalAddResa.show()
			},
			closeModal() {
				this.$refs.modalAddResa.hide()
			},
			resetModal() {
				this.form = _cloneDeep(this.default_form)
				this.quantity = 0
				// this.contract = null
				this.monte_resa_id = 0
			},
			async checkForm() {
				let errors = []
				this.processing = true
				this.form_error = ''

				const required = {
					'destinataire': 'need_destinataire',
					'stallion': 'need_stallion'
				}

				Object.keys(required).forEach(input => {
					if(!this.form[input]) {
						const trad = required[input]
						errors.push(trad)
					}
				})

				// On vérifie la quantité
				if(this.quantity < 0) {
					errors.push('need_quantity')
				}
				
				if(errors.length > 0) {
					this.form_error = errors.map(err => this.$t(`monte.reservation.form_error.${err}`))
						.join('. ')

					this.processing = false
					return
				}

				this.form.doses = 0
				this.form.paillettes = 0

				if(this.quantity_type == "doses") this.form.doses = this.quantity
				else this.form.paillettes = this.quantity

				const temp = {
					destinataire: this.form.destinataire.tiers_id,
					stallion: 	this.form.stallion.horse_id,
					doses: this.form.doses,
					paillettes: this.form.paillettes,
					comment: this.form.comment
				}

				if(this.form.expediteur) temp['expediteur'] = this.form.expediteur.tiers_id
				if(this.form.client) temp['client'] = this.form.client.tiers_id
				if(this.form.mare) temp['mare'] = this.form.mare.horse_id
				if(this.contract)  temp['contract'] = this.contract.contract_id
				temp['delivery_date'] = this.form.delivery_date

				let promise = null

				if(this.monte_resa_id > 0) {
					temp.canceled = this.canceled
					promise = this.editContractSailliesReservations(this.monte_resa_id, temp)
				}
				else {
					const contract_id = this.contract_id || this.contract ? this.contract.contract_id : 0
					promise = this.addContractSailliesReservations(contract_id, temp)
				}

				return promise
					.then(_ => {
						this.closeModal()
						this.resetModal()
						this.$emit('ok')
					})
					.catch(_ => {

					})
					.finally(() => {
						this.processing = false
					})
			}
		},
		components: {
			Etalon : () => import('@/components/Contract/Type/Etalon'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
		}
	}

</script>